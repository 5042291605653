import $, { data } from "jquery"
import icons from "../icons/icons"
import Swiper, {
  Autoplay,
  Navigation,
  Pagination,
  EffectCoverflow,
  EffectFade,
  Thumbs,
  Lazy,
} from "swiper"
import "swiper/swiper-bundle.css"
import LazyLoad from "vanilla-lazyload"
import useTagify from "@yaireo/tagify"
import "@yaireo/tagify/dist/tagify.css"
import tippy, { delegate } from "tippy.js"
import "tippy.js/dist/tippy.css"
import "tippy.js/animations/scale.css"
import "select2"
import "select2/dist/css/select2.css"
import * as noUiSlider from "nouislider"
import "nouislider/dist/nouislider.css"
import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox.js"
import "@fancyapps/ui/dist/fancybox.css"
import Headroom from "headroom.js"
import useToastr from "toastr"
import { CountUp } from "countup.js"

useToastr.options = {
  progressBar: true,
  positionClass: "toast-top-right",
  onclick: null,
  onCloseClick: true,
  showDuration: "300",
  hideDuration: "1000",
  timeOut: "2500",
  extendedTimeOut: "2500",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
}

window.toastrMessage = function (type, message) {
  useToastr[type](message)
}
window.alertModal = function (type, message) {
  $(".alert-modal").addClass("active")
  $(".alert-modal").find(".alert-modal__title").text(message)
  $(".alert-modal").addClass(`alert-modal--${type}`)
  if (type === "success") {
    $(".success").removeClass("d-none")
    $(".danger").addClass("d-none")
    $(".info").addClass("d-none")
    $(".warning").addClass("d-none")
  }
  if (type === "danger") {
    $(".danger").removeClass("d-none")
    $(".success").addClass("d-none")
    $(".info").addClass("d-none")
    $(".warning").addClass("d-none")
  }
  if (type === "info") {
    $(".info").removeClass("d-none")
    $(".danger").addClass("d-none")
    $(".success").addClass("d-none")
    $(".warning").addClass("d-none")
  }
  if (type === "warning") {
    $(".warning").removeClass("d-none")
    $(".danger").addClass("d-none")
    $(".success").addClass("d-none")
    $(".info").addClass("d-none")
  }
}
$(".alert-modal__overlay").on("click", () => {
  $(".alert-modal").removeClass("active")
  $(".alert-modal").removeClass(
    "alert-modal--success alert-modal--info alert-modal--danger active alert-modal--warning"
  )
})
window.onload = function () {
  if (document.querySelector(".preloader")) {
    document.querySelector(".preloader").classList.add("hidden")
  }
}

var lazyLoadInstance = new LazyLoad({})

tippy("[data-tippy-content]", {
  animation: "scale",
})
delegate(".js-product-filter-select-items", {
  target: ".js-product-filter-select-item",
  animation: "scale",
})

const template = document.getElementById("template")
const offerModal = document.querySelector("#offerModal")
if (template) {
  // template.style.display = "block"
  tippy(".product-filter__foot", {
    content: template.innerHTML,
    allowHTML: true,
    trigger: "click",
    interactive: true,
    theme: "alert",
    animation: "scale",

    onShow(instance) {
      instance.popper
        .querySelector(".js-tippy-close")
        .addEventListener("click", () => {
          instance.hide()
        })
      instance.popper
        .querySelector(".js-product-filter-reset")
        .addEventListener("click", () => {
          instance.hide()
        })
    },
  })
}
if (offerModal) {
  tippy(".js-offer-basket-delete-all", {
    content: offerModal.innerHTML,
    allowHTML: true,
    trigger: "click",
    interactive: true,
    theme: "alert",
    animation: "scale",
    onTrigger(instance, event) {
      if ($(".js-offer-basket-item").length == 0) {
        instance.disable()
      }
    },
    onShow(instance) {
      instance.popper
        .querySelector(".js-tippy-close")
        .addEventListener("click", () => {
          instance.hide()
        })
      instance.popper
        .querySelector(".js-offer-basket-clear")
        .addEventListener("click", () => {
          instance.hide()
        })
    },
  })
}

Swiper.use([
  Thumbs,
  EffectCoverflow,
  Navigation,
  Pagination,
  Autoplay,
  EffectFade,
  Lazy,
])

var app = {
  iconSpriteFn() {
    icons.forEach(iconSpriteFn)
    function iconSpriteFn(item, index) {
      const iconSprite = document.querySelector("#iconSprite")
      if (iconSprite) {
        iconSprite.innerHTML +=
          "<div class='icon-sprite__item'>" +
          "<span class='icon-sprite__number'>" +
          (index + 1) +
          "</span>" +
          "<div class='icon-sprite__preview'>" +
          item.iconSvg +
          "</div>" +
          "<div class='icon-sprite__name'>" +
          item.iconId +
          "</div>" +
          "</div>"
      }

      const icon = document.querySelectorAll(".icon")
      if (icon) {
        Array.prototype.forEach.call(icon, (el) => {
          let dataIconId = el.getAttribute("data-icon-id")
          if (dataIconId == item.iconId) {
            el.innerHTML = item.iconSvg
          }
        })
      }
    }
  },

  // Filter Tab
  filterTabFn() {
    const filterTab = document.querySelectorAll(".filter-tab")
    if (filterTab) {
      filterTab.forEach((filterTabEl) => {
        const tabItem = filterTabEl.querySelectorAll(".tab-item")
        const tabContent = filterTabEl.querySelectorAll(".tab-content")
        const tabContentParent = filterTabEl.querySelector(
          ".filter-tab-content"
        )
        let filterTabAnimation = filterTabEl.getAttribute(
          "data-filter-animation"
        )
        filterTabEl.classList.add(`filter-tab--${filterTabAnimation}`)

        tabItem.forEach((tabItemEl) => {
          tabItemEl.addEventListener("click", () => {
            if (!tabItemEl.classList.contains("active")) {
              //tab item
              let tabItemDataFilter = tabItemEl.getAttribute("data-filter")
              let tabItemActive =
                filterTabEl.querySelectorAll(".tab-item.active")
              tabItemActive.forEach((tabItemActiveEl) => {
                tabItemActiveEl.classList.remove("active")
              })
              tabItemEl.classList.add("active")

              //tab content
              tabContent.forEach((tabContentEl) => {
                tabContentEl.classList.add("filtering")
                setTimeout(() => {
                  tabContentEl.classList.remove("filtering")
                  tabContentEl.classList.add("filtered")
                }, 250)
              })
              tabContentParent
                .querySelectorAll(`.${tabItemDataFilter}`)
                .forEach((tabItemDataFilterEl) => {
                  setTimeout(() => {
                    tabItemDataFilterEl.classList.remove("filtered")
                  }, 250)
                })
            }
          })
        })
      })
    }
  },
  // #Filter Tab

  headerFn() {
    const header = document.querySelector(".header")
    const mainDetailPage = document.querySelector(".main-detail-page")
    const rotaFinderFilter = document.querySelector(".js-rota-finder-filter")
    if (header) {
      const navBtn = document.querySelector(".js-nav-btn")
      var clicked = false

      var options = {
        offset: 40,
        tolerance: 2,
        classes: {
          initial: "header",
          pinned: "header--pinned",
          unpinned: "header--unpinned",
          top: "header--top",
          notTop: "header--sticky header--white",
          bottom: "header--bottom",
          notBottom: "header--not-bottom",
          frozen: "header--frozen",
          pinned: "header--pinned",
        },
        onTop: function () {
          if (mainDetailPage) {
            this.elem.classList.add("header--white")
          } else {
            this.elem.classList.remove("header--white")
          }
          if (clicked) {
            this.elem.classList.add("header--white")
          }

          if (window.matchMedia("(max-width: 993px)").matches) {
            if (rotaFinderFilter) {
              rotaFinderFilter.classList.add("rota-finder__filter--top")
            }
          }
        },
        onNotTop: function () {
          if (window.matchMedia("(max-width: 993px)").matches) {
            if (rotaFinderFilter) {
              rotaFinderFilter.classList.remove("rota-finder__filter--top")
            }
          }
        },
        onPin: function () {
          if (window.matchMedia("(max-width: 993px)").matches) {
            if (rotaFinderFilter) {
              rotaFinderFilter.classList.add("rota-finder__filter--pinned")
            }
          }
        },
        onUnpin: function () {
          if (window.matchMedia("(max-width: 993px)").matches) {
            if (rotaFinderFilter) {
              rotaFinderFilter.classList.remove("rota-finder__filter--pinned")
            }
          }
        },
      }

      var headroom = new Headroom(header, options)
      headroom.init()

      function menuOpened() {
        navBtn.classList.add("open")
        header.classList.add(
          "header--with-dropdown",
          "header--white",
          "header--menu-opened"
        )
        if (window.matchMedia("(max-width: 993px)").matches) {
          document.querySelector("html").classList.add("menu-opened")
          document.querySelector("body").classList.add("menu-opened")
        }
      }

      function menuClosed() {
        navBtn.classList.remove("open")
        header.classList.remove("header--with-dropdown", "header--menu-opened")
        if (window.matchMedia("(max-width: 993px)").matches) {
          document.querySelector("html").classList.remove("menu-opened")
          document.querySelector("body").classList.remove("menu-opened")
        }
      }

      window.addEventListener("click", function (e) {
        clicked = !clicked
        if (document.querySelector(".js-nav-btn").contains(e.target)) {
          if (clicked) {
            menuOpened()
          } else {
            menuClosed()
            if (header.classList.contains("header--top")) {
              if (!mainDetailPage) {
                header.classList.remove("header--white")
              }
            }
            clicked = false
          }
        } else {
          menuClosed()
          if (header.classList.contains("header--top")) {
            if (!mainDetailPage) {
              header.classList.remove("header--white")
            }
          }
          clicked = false
        }
      })
      window.addEventListener("click", function (e) {
        if (document.querySelector(".header__nav").contains(e.target)) {
          menuOpened()

          clicked = !clicked
        }
      })

      const headerTitle = document.querySelectorAll(".js-header-nav-title")
      headerTitle.forEach((el) => {
        el.addEventListener("click", function () {
          const elParent = el.closest(".header__nav-content")
          if (elParent.classList.contains("opened")) {
            elParent.classList.remove("opened")
            elParent.querySelector(".header__nav-list").style.height = 0 + "px"
          } else {
            document
              .querySelectorAll(".header__nav-content.opened")
              .forEach((element) => {
                element.classList.remove("opened")
                element.querySelector(".header__nav-list").style.height =
                  0 + "px"
              })
            elParent.classList.add("opened")
            const listHeight = elParent.querySelector(
              ".js-header-nav-list"
            ).offsetHeight
            elParent.querySelector(".header__nav-list").style.height =
              listHeight + "px"
          }
        })
      })
    }
  },

  heroSearchFn() {
    const btn = document.querySelector(".js-hero-search-multiple-btn")
    const inputField = document.querySelector(".js-hero-search-input")
    const inputMultipleField = document.querySelector(
      ".js-hero-search-multiple-input"
    )
    const inputMultiple = document.querySelector(
      ".js-hero-search-multiple-input input"
    )

    if (inputMultiple) {
      var tagify = new useTagify(inputMultiple, {
        delimiters: ",|\n|\r",
        maxTags: 30,
        callbacks: {
          change: (e) => {
            const tagWrapper = document.querySelector(".tagify")
            if (tagWrapper.classList.contains("tagify--hasMaxTags")) {
              alert("max tag reached")
            }
          },
          add: ({ detail: { tag } }) => {
            tag.style = "--tag-bg: #ffffff; --tag-border-radius: 1rem"
          },
        },
      })
    }

    if (btn) {
      btn.addEventListener("click", () => {
        btn
          .closest(".main-search")
          .querySelector(".js-hero-search-input input").value = ""
        btn.classList.toggle("is-active")
        if (btn.classList.contains("is-active")) {
          inputField.style.display = "none"
          inputMultipleField.style.display = "block"
        } else {
          inputField.style.display = "block"
          inputMultipleField.style.display = "none"
        }
      })
    }
    const header = document.querySelector(".header")
    const headerSearchBtn = document.querySelector(".js-header-search-btn")
    const headerSearch = document.querySelector(".header__search")
    if (headerSearchBtn) {
      window.addEventListener("click", function (e) {
        if (
          document.querySelector(".js-header-search-btn").contains(e.target)
        ) {
          headerSearch.classList.toggle("opened")
          headerSearchBtn.classList.toggle("active")
          header.classList.toggle("header--search-opened")
        } else {
          headerSearch.classList.remove("opened")
          headerSearchBtn.classList.remove("active")
          header.classList.remove("header--search-opened")
        }
      })
      window.addEventListener("click", function (e) {
        if (document.querySelector(".header__search").contains(e.target)) {
          headerSearch.classList.add("opened")
          headerSearchBtn.classList.add("active")
          header.classList.add("header--search-opened")
        }
      })
    }
  },

  heroSliderFn() {
    var sliderCount = document.querySelector(".js-hero-slider-count")
    var sliderCountLength = document.querySelector(
      ".js-hero-slider-count-length"
    )
    var duration = 10000
    function heroVideo(e) {
      e.slides.forEach((element, i) => {
        const heroVideos = element.querySelectorAll(".js-hero-video")
        heroVideos.forEach((heroVideo) => {
          if (e.activeIndex === i) {
            if (heroVideo) {
              heroVideo.play()
            }
          } else {
            if (heroVideo) {
              heroVideo.pause()
              setTimeout(() => {
                heroVideo.currentTime = 0
              }, 500)
            }
          }
        })
      })
    }
    var swiper = new Swiper(".js-hero-thumb-slider", {
      slidesPerView: 1,
      watchSlidesProgress: true,
      speed: 500,
      loop: false,
      effect: "fade",
      // preloadImages: true,
      // lazy: true,
      on: {
        init: function (e) {
          heroVideo(e)
        },
        slideChange: function (e) {
          heroVideo(e)
        },
      },
    })

    var swiper2 = new Swiper(".js-hero-main-slider", {
      effect: "coverflow",
      loop: false,
      speed: 500,
      preloadImages: false,
      lazy: true,
      navigation: {
        nextEl: ".hero__swiper-buttons .btn-next-hero",
        prevEl: ".hero__swiper-buttons .btn-prev-hero",
      },
      thumbs: {
        swiper: swiper,
      },
      autoplay: {
        delay: duration,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      coverflowEffect: {
        rotate: 100,
        stretch: 150,
        depth: 0,
        modifier: 1,
        scale: 0,
        slideShadows: false,
      },

      on: {
        init: function (e) {
          console.log(e.params.autoplay.delay)
          if (e.realIndex + 1 >= 10) {
            sliderCount.innerHTML = e.realIndex + 1
          } else {
            sliderCount.innerHTML = "0" + (e.realIndex + 1)
          }
          if (e.slides.length >= 10) {
            sliderCountLength.innerHTML = e.slides.length - 2
          } else {
            sliderCountLength.innerHTML = "0" + e.slides.length
          }
        },
        slideChange: function (e) {
          console.log(e.params.autoplay.delay)
          if (e.realIndex + 1 >= 10) {
            sliderCount.innersticHTML = e.realIndex + 1
          } else {
            sliderCount.innerHTML = "0" + (e.realIndex + 1)
          }
          if (e.slides.length >= 10) {
            sliderCountLength.innerHTML = e.slides.length - 2
          } else {
            sliderCountLength.innerHTML = "0" + e.slides.length
          }
        },
      },
    })

    const videos = document.querySelectorAll(".js-hero-video")
    const currentVideo = videos[swiper2.realIndex]
    if (currentVideo) {
      currentVideo.addEventListener("loadedmetadata", function () {
        swiper2.params.autoplay.delay =
          currentVideo.duration * 1000 - swiper2.params.speed
        swiper2.autoplay.start() // Start autoplay after setting the delay
      })
    }
  },

  headerLanguageFn() {
    const headerLanguageBtn = document.querySelector(".js-header-language-btn")
    if (headerLanguageBtn) {
      window.addEventListener("click", function (e) {
        if (document.querySelector(".header__language").contains(e.target)) {
          headerLanguageBtn.parentNode.classList.toggle("opened")
        } else {
          headerLanguageBtn.parentNode.classList.remove("opened")
        }
      })
      window.addEventListener("click", function (e) {
        if (
          document.querySelector(".header__language-wrapper").contains(e.target)
        ) {
          document
            .querySelector(".header__language-wrapper")
            .parentNode.classList.add("opened")
        }
      })
    }
  },

  sharePopupFn() {
    const sharePopupBtn = document.querySelector(".js-share-popup-btn")
    if (sharePopupBtn) {
      window.addEventListener("click", function (e) {
        if (document.querySelector(".share-popup").contains(e.target)) {
          sharePopupBtn.parentNode.classList.toggle("opened")
        } else {
          sharePopupBtn.parentNode.classList.remove("opened")
        }
      })
      window.addEventListener("click", function (e) {
        if (document.querySelector(".share-popup__popup").contains(e.target)) {
          document
            .querySelector(".share-popup__popup")
            .parentNode.classList.add("opened")
        }
      })
    }
  },

  rotaFinderFn() {
    var btn = document.querySelector(".js-open-rota-filter")
    var btnClose = document.querySelector(".js-close-rota-filter")
    if (btn) {
      btn.addEventListener("click", function () {
        document.querySelector(".js-rota-finder-filter").classList.add("opened")
      })
      btnClose.addEventListener("click", function () {
        document
          .querySelector(".js-rota-finder-filter")
          .classList.remove("opened")
      })
    }
  },

  mainTab: function () {
    $("body").on("click", ".js-tab-item", function (e) {
      $(".js-tab-item").removeClass("active")
      $(".js-tab-item.active").removeClass("active")
      $('.js-tab-item[data-filter="' + $(this).data("filter") + '"]').addClass(
        "active"
      )
      var dataFilter = $(this).data("filter")
      $(".filter-tab-item").delay(250).addClass("filtering")
      // $('.js-filter-tab-item.' + dataFilter).removeClass('filtering');
      window.setTimeout(function () {
        $(".filter-tab-item").removeClass("filtering")
        $(".filter-tab-item").addClass("filtered")
        $(".filter-tab-item." + dataFilter).removeClass("filtered")
      }, 250)
      e.preventDefault()
      return false
    })
  },

  productContentSliderFn() {
    var productContentSwiper = new Swiper(".js-product-content-slider", {
      spaceBetween: 0,
      slidesPerView: 3,
      speed: 400,
      observer: true,
      observeParents: true,
      preloadImages: false,
      lazy: true,
      pagination: {
        el: ".product-group__content .swiper-pagination",
        type: "bullets",
      },
      breakpoints: {
        1200: {
          slidesPerView: 3,
        },
        768: {
          slidesPerView: 2,
        },
        320: {
          slidesPerView: 1,
        },
      },
    })
  },

  productCardSlider() {
    var productCardSwiper = new Swiper(".js-product-card-slider", {
      spaceBetween: 0,
      slidesPerView: 1,
      speed: 400,
      lazy: true,
      pagination: {
        el: ".js-product-card-slider .swiper-pagination",
        type: "bullets",
      },
    })
  },

  load() {
    console.log("load")
  },
  resized() {
    console.log("resized")
  },

  accordionFn() {
    $("body").on("click", ".js-accordion-trigger", function () {
      $(this).toggleClass("active")
      const accordionGroup = $(this).closest(".accordion-wrapper")
      const item = $(this).closest(".accordion-item")
      let multipleShow = false

      if (accordionGroup.data("multiple-show") == true) {
        multipleShow = true
      } else {
        $(".js-accordion-trigger").not(this).removeClass("active")
      }
      let content = item.find(".accordion-calc")
      if (item.hasClass("active") && !$(this).hasClass("force-open")) {
        item.removeClass("active")
      } else {
        if (!multipleShow) {
          accordionGroup.children(".accordion-item").removeClass("active")
        }
        item.addClass("active")
      }
    })

    $(".accordion-item.opened .js-accordion-trigger").trigger("click")
  },
  uikitFunctions() {
    function uikitSide() {
      $(document).on("scroll", onScroll)
      function onScroll(event) {
        var scrollPos = $(document).scrollTop()
        $(".js-uikit-side-item").each(function () {
          var refElement = $($(this).attr("href"))
          if (refElement.position().top <= scrollPos + 50) {
            var id = $(this).attr("href")

            $(".uikit__item " + id).addClass("active")
            $(".js-uikit-side-item").removeClass("active")
            $(this).addClass("active")
          } else {
            $(this).removeClass("active")
          }
        })
      }
    }

    function uikitSideItem() {
      $(".uikit__item").each(function () {
        var id = this.id
        var uikitSide = $(".uikit__side")
        let uikitSideEl = `<a href="#${id}" class="uikit__side-item js-uikit-side-item"> ${titleCase(
          id
        )
          .split("-")
          .join(" ")}</a>`
        uikitSide.append(uikitSideEl)
      })
      function titleCase(str) {
        var splitStr = str.toLowerCase().split(" ")
        for (var i = 0; i < splitStr.length; i++) {
          splitStr[i] =
            splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
        }
        return splitStr.join(" ")
      }
    }
    function uikitPageDropdown() {
      $(".js-uikit-pages-dropdown").on("click", (e) => {
        $(".front-ui-dropdown").toggleClass("active")
      })
    }
    uikitPageDropdown()
    uikitSide()
    uikitSideItem()
  },
  productNumber() {
    $(".js-minus").click(function () {
      var input = $(this).siblings(".productNumber")
      if (input.val() != 1) {
        if (parseFloat(input.val()) > 1) input.val(parseInt(input.val()) - 1)
      }
    })
    $(".js-plus").click(function () {
      var input = $(this).siblings(".productNumber")
      input.val(parseInt(input.val()) + 1)
    })
  },

  scrollContainFn() {
    const scrollContain = document.querySelectorAll(".js-scroll-contain")
    const scrollBtnIcon = `<div class="scroll-contain__icon"><span class="icon icon-font"><svg viewBox="0 0 12 20" xmlns="http://www.w3.org/2000/svg">
    <path  d="M11.5816 0.418419C12.1395 0.976311 12.1395 1.88083 11.5816 2.43872L4.0203 10L11.5816 17.5613C12.1395 18.1192 12.1395 19.0237 11.5816 19.5816C11.0237 20.1395 10.1192 20.1395 9.56128 19.5816L0.989847 11.0102C0.431955 10.4523 0.431955 9.54774 0.989847 8.98985L9.56128 0.418419C10.1192 -0.139473 11.0237 -0.139473 11.5816 0.418419Z"/>
    </svg></span></div>`
    const scrollBtnGradient = `<span class="scroll-contain__gradient"></span>`
    scrollContain.forEach((e) => {
      const scrollBtnCreate = document.createElement("div")
      scrollBtnCreate.setAttribute(
        "class",
        "scroll-contain__btn js-scroll-contain-btn"
      )
      scrollBtnCreate.innerHTML = scrollBtnGradient + scrollBtnIcon
      let height = e.querySelector(
        ".js-calc-height-scroll-contain"
      ).offsetHeight
      let controlData = e.getAttribute("data-control-height")
      if (controlData === "100") {
        if (height >= controlData) {
          e.appendChild(scrollBtnCreate)
          e.classList.add(`scroll-contain--h${controlData}`)
        }
      }
      if (controlData === "250") {
        if (height >= controlData) {
          e.appendChild(scrollBtnCreate)
          e.classList.add(`scroll-contain--h${controlData}`)
        }
      }
      if (controlData === "350") {
        if (height >= controlData) {
          e.appendChild(scrollBtnCreate)
          e.classList.add(`scroll-contain--h${controlData}`)
        }
      }
      if (controlData === "450") {
        if (height >= controlData) {
          e.appendChild(scrollBtnCreate)
          e.classList.add(`scroll-contain--h${controlData}`)
        }
      }
      const scrollBtn = e.querySelector(".js-scroll-contain-btn")
      var heightBoolean = false
      if (scrollBtn) {
        scrollBtn.addEventListener("click", () => {
          heightBoolean = !heightBoolean
          if (heightBoolean) {
            scrollBtn.parentNode.style.height = height + "px"
            scrollBtn.parentNode.classList.add("active")
          } else {
            scrollBtn.parentNode.style.height = null
            scrollBtn.parentNode.classList.remove("active")
          }
        })
      }
    })
  },

  switchFn() {
    $(document).on("change", ".js-switch input", function () {
      if (this.checked) {
        $(".switch__text--last").addClass("txt--font600 c-secondary")
        $(".switch__text--first").removeClass("txt--font600 c-secondary")
        $(".sec-inc").removeClass("d-none")
        $(".sec-mm").addClass("d-none")
      } else {
        $(".switch__text--last").removeClass("txt--font600 c-secondary")
        $(".switch__text--first").addClass("txt--font600 c-secondary")
        $(".sec-mm").removeClass("d-none")
        $(".sec-inc").addClass("d-none")
      }
    })
  },
  sortListFn() {
    const sortByBtn = document.querySelector(".js-filter-btn")
    $(".main-filter__popup-item").on("click", function () {
      if (!this.classList.contains("active")) {
        $(".main-filter__popup-item").removeClass("active")
        this.classList.add("active")
      }
    })
    if (sortByBtn) {
      window.addEventListener("click", function (e) {
        if (
          document.querySelector(".main-filter__wrapper").contains(e.target)
        ) {
          sortByBtn.parentNode.classList.toggle("opened")
        } else {
          sortByBtn.parentNode.classList.remove("opened")
        }
      })
      window.addEventListener("click", function (e) {
        if (document.querySelector(".main-filter__popup").contains(e.target)) {
          document
            .querySelector(".main-filter__popup")
            .parentNode.classList.add("opened")
        }
      })
    }
  },

  select2Options() {
    $(".js-select").each(function () {
      $(this)
        .find("select")
        .select2({
          dropdownParent: $(this).closest(".js-select"),
          placeholder: $(this).attr("data-placeholder"),
          templateResult: function (data, container) {
            if (data.element) {
              $(container).addClass($(data.element).attr("id"))
            }
            return data.text
          },
        })
      $(this)
        .find("select")
        .change(function () {
          if ($(this).val() !== "seciniz") {
            $(this).closest(".select").addClass("select--primary")
          } else {
            $(this).closest(".select").removeClass("select--primary")
          }
        })
    })
    function format(item, state) {
      if (!item.id) {
        return item.text
      }
      // var countryUrl = "/asset/images/flags/"
      var dataSrc = item.element.getAttribute("data-src")

      var img = $("<img>", {
        class: "img-flag",
        src: dataSrc ? dataSrc : "/asset/images/flags/empty.png",
      })
      var span = $("<span>", {
        text: " " + item.text,
      })
      span.prepend(img)
      return span
    }

    $(document).ready(function () {
      $(".js-country-select select").select2({
        dropdownParent: $(".js-country-select"),
        placeholder: $(this).attr("data-placeholder"),
        templateResult: function (item) {
          return format(item, false)
        },
      })
    })
  },

  inputFn() {
    const inputItem = document.querySelectorAll(
      ".input-item input, .input-item textarea"
    )
    inputItem.forEach((e) => {
      e.addEventListener("focusin", () => {
        e.parentNode.classList.add("input-item--focused")
      })
      e.addEventListener("focusout", () => {
        if (e.value == "") {
          e.parentNode.classList.remove("input-item--focused")
        } else {
          e.parentNode.classList.add("input-item--focused")
        }
      })
    })

    // File Upload
    const dt = new DataTransfer()
    $("#file").on("change", function (e) {
      for (var i = 0; i < this.files.length; i++) {
        if (this.files[i].size < 5242880) {
          let fileBloc = $("<span/>", { class: "file-upload-item" }),
            fileName = $("<span/>", {
              class: "file-upload-item__name",
              text: this.files.item(i).name,
            })
          let deleteItem = `<span class="file-upload-item__delete"><span class="icon icon-font"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14"><path d="M1,14c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l12-12c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-12,12C1.5,13.9,1.3,14,1,14z"/><path d="M13,14c-0.3,0-0.5-0.1-0.7-0.3l-12-12c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l12,12c0.4,0.4,0.4,1,0,1.4 C13.5,13.9,13.3,14,13,14z"/></svg></span></span>`
          fileBloc.append(deleteItem).append(fileName)
          $(".file-upload__list > .file-upload__items").append(fileBloc)
          $(".input-item--file").addClass("input-item--file-active")
          // Dosya yükleme sınırlaması
          // // if (dt.files.length < 5) {
          // //   $(".file-upload__list > .file-upload__items").append(fileBloc)
          // //   $(".input-item--file").addClass("input-item--file-active")
          // // } else {
          // //   alert("5ten fazla yüklenemez.")
          // //   dt.clearData()
          // //   $(".file-upload__list > .file-upload__items").children().remove()
          // //   $(".file-upload__list > .file-upload__items").append(fileBloc)
          // //   $(".input-item--file").removeClass("input-item--file-active")
          // // }
        }
      }

      if (this.files[0].size < 5242880) {
        // Sınırlama için; && dt.files.length < 5
        for (let file of this.files) {
          dt.items.add(file)
        }
        this.files = dt.files
      } else {
        alert("Dosya boyutu 5MB'den fazla olamaz")
      }

      $("span.file-upload-item__delete").click(function () {
        let name = $(this).next("span.file-upload-item__name").text()
        $(this).parent().remove()
        for (let i = 0; i < dt.items.length; i++) {
          if (name === dt.items[i].getAsFile().name) {
            dt.items.remove(i)
            $(".input-item--file").removeClass("input-item--file-active")
            continue
          }
        }
        document.querySelector("#file").files = dt.files
      })
      document.querySelector("#file").files = dt.files
    })
  },

  productFilterFn() {
    var outerControlBoolean = true

    $(".product-filter").each(function () {
      var appendItems = $(this).find(".js-product-filter-select-items")

      if (
        $(this)
          .closest(".product-filter")
          .find(".js-product-filter-select-item").length === 0
      ) {
        outerControlBoolean = true
      } else {
        outerControlBoolean = false
      }
      if (outerControlBoolean) {
        $(this)
          .closest(".product-filter")
          .find(".js-product-filter-select")
          .css("display", "none")
      } else {
        $(this)
          .closest(".product-filter")
          .find(".js-product-filter-select")
          .css("display", "block")
      }

      $(this)
        .find(".checkbox input")
        .each(function () {
          $(this).on("change", function () {
            var items = []
            var val = $(this).val()

            var appendBadge = `<div class="badge badge--default js-product-filter-select-item" data-tippy-content="Kaldır" data-value="${val}">
          <div class="badge__txt txt txt--px18 txt--font400">${val}</div><span class="badge__icon icon icon-font" data-icon-id="iconClose">    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
          viewBox="0 0 512.001 512.001">
         <path d="M284.286,256.002L506.143,34.144c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.81-20.475-7.811-28.285,0L256,227.717
           L34.143,5.859c-7.811-7.811-20.475-7.811-28.285,0c-7.81,7.811-7.811,20.475,0,28.285l221.857,221.857L5.858,477.859
           c-7.811,7.811-7.811,20.475,0,28.285c3.905,3.905,9.024,5.857,14.143,5.857c5.119,0,10.237-1.952,14.143-5.857L256,284.287
           l221.857,221.857c3.905,3.905,9.024,5.857,14.143,5.857s10.237-1.952,14.143-5.857c7.811-7.811,7.811-20.475,0-28.285
           L284.286,256.002z"/> </svg></span></div>`
            if ($(this).is(":checked")) {
              appendItems.append(appendBadge)
              var textVal = $(this)
                .closest(".product-filter")
                .find(".js-product-filter-select .badge__txt")
              textVal.each(function () {
                items.push($(this).text())
              })
              $(this)
                .closest(".product-filter")
                .find(".box")
                .val(items.join("|"))
            } else {
              $(this)
                .closest(".product-filter")
                .find(".js-product-filter-select-item")
                .each(function () {
                  if (val === $(this).data("value")) {
                    $(this).remove()
                  }
                })
              var textVal = $(this)
                .closest(".product-filter")
                .find(".js-product-filter-select .badge__txt")
              textVal.each(function () {
                items.push($(this).text())
              })
              $(this)
                .closest(".product-filter")
                .find(".box")
                .val(items.join("|"))
            }
            if (
              $(this)
                .closest(".product-filter")
                .find(".js-product-filter-select-item").length === 0
            ) {
              outerControlBoolean = true
            } else {
              outerControlBoolean = false
            }
            if (outerControlBoolean) {
              $(this)
                .closest(".product-filter")
                .find(".js-product-filter-select")
                .css("display", "none")
            } else {
              $(this)
                .closest(".product-filter")
                .find(".js-product-filter-select")
                .css("display", "block")
            }
            $("body").delegate(
              ".js-product-filter-select-items .js-product-filter-select-item",
              "click",
              function () {
                var thisVal = $(this).data("value")

                $(this)
                  .closest(".product-filter")
                  .find(`.checkbox input[value='${thisVal}']`)
                  .prop("checked", "")

                items = []
                $(this)
                  .closest(".js-product-filter-select-items")
                  .find(".js-product-filter-select-item")
                  .each(function (e, v) {
                    if ($(v).data("value") !== thisVal) {
                      items.push($(v).data("value"))
                    }
                  })
                $(this)
                  .closest(".product-filter")
                  .find(".box")
                  .val(items.join("|"))

                if (
                  $(this)
                    .closest(".product-filter")
                    .find(".js-product-filter-select-item").length === 1
                ) {
                  outerControlBoolean = true
                } else {
                  outerControlBoolean = false
                }
                if (outerControlBoolean) {
                  $(this)
                    .closest(".product-filter")
                    .find(".js-product-filter-select")
                    .css("display", "none")
                } else {
                  $(this)
                    .closest(".product-filter")
                    .find(".js-product-filter-select")
                    .css("display", "block")
                }

                $(this).remove()
              }
            )
          })
        })
    })

    $("body").delegate(".js-product-filter-reset", "click", function () {
      $(this).closest(".product-filter")[0].reset()
      $(this).closest(".product-filter").find(".box").val("")
      $(this)
        .closest(".product-filter")
        .find(".js-product-filter-select-item")
        .remove()
      if (
        $(this)
          .closest(".product-filter")
          .find(".js-product-filter-select-item").length === 0
      ) {
        outerControlBoolean = true
      } else {
        outerControlBoolean = false
      }
      if (outerControlBoolean) {
        $(this)
          .closest(".product-filter")
          .find(".js-product-filter-select")
          .css("display", "none")
      } else {
        $(this)
          .closest(".product-filter")
          .find(".js-product-filter-select")
          .css("display", "block")
      }
    })
  },
  noResultFn() {
    if ($(".rota-finder__products-list").children().length == 0) {
      $(".rota-finder__results").children().hide()
      $(".rota-finder__filter").hide()
      $(".rota-finder__results").append(`
      <div class="main__search-results txt txt--px30 txt--font400">
      <div class="main__search-results--notfound"><span class="icon icon-font" data-icon-id="iconEmptyBasket">
      <svg  viewBox="0 0 444 461" xmlns="http://www.w3.org/2000/svg">
    <path d="M310 175.5C310 185.441 301.941 193.5 292 193.5C282.059 193.5 274 185.441 274 175.5C274 165.559 282.059 157.5 292 157.5C301.941 157.5 310 165.559 310 175.5Z" />
    <path d="M130 175.5C130 185.441 121.941 193.5 112 193.5C102.059 193.5 94 185.441 94 175.5C94 165.559 102.059 157.5 112 157.5C121.941 157.5 130 165.559 130 175.5Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M231 333.5C231 285.934 269.559 247.375 317.125 247.375C364.691 247.375 403.25 285.934 403.25 333.5C403.25 381.066 364.691 419.625 317.125 419.625C269.559 419.625 231 381.066 231 333.5ZM317.125 260.625C276.877 260.625 244.25 293.252 244.25 333.5C244.25 373.748 276.877 406.375 317.125 406.375C357.373 406.375 390 373.748 390 333.5C390 293.252 357.373 260.625 317.125 260.625ZM307.926 280.007C308.498 283.621 306.032 287.015 302.419 287.587C286.355 290.131 273.756 302.73 271.212 318.794C270.64 322.408 267.246 324.873 263.632 324.301C260.018 323.728 257.553 320.335 258.125 316.721C261.567 294.988 278.612 277.943 300.346 274.5C303.96 273.928 307.353 276.394 307.926 280.007ZM383.108 399.483C385.695 396.896 389.89 396.896 392.477 399.483L441.809 448.815C444.397 451.403 444.397 455.597 441.809 458.185C439.222 460.772 435.027 460.772 432.44 458.185L383.108 408.852C380.52 406.265 380.52 402.07 383.108 399.483Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M99.35 90C105.969 39.213 149.39 0 201.977 0C254.563 0 297.989 39.2172 304.604 90L316.53 90C334.452 89.9994 349.314 89.999 360.979 91.6287C373.241 93.3421 384.259 97.1252 392.639 106.487C401.018 115.85 403.562 127.218 403.91 139.595C404.242 151.368 402.6 166.139 400.621 183.951L395.079 233.83L395.078 233.832C392.878 253.632 390.929 271.174 388.757 286.68C382.257 277.774 373.949 270.27 364.374 264.708C365.647 254.197 366.933 242.642 368.351 229.887L373.676 181.96C375.793 162.903 377.193 150.019 376.921 140.355C376.66 131.078 374.873 127.123 372.521 124.494C370.168 121.866 366.434 119.653 357.242 118.369C347.668 117.031 334.708 117 315.534 117H88.4197C69.2455 117 56.2857 117.031 46.7109 118.369C37.5189 119.653 33.7856 121.866 31.4328 124.494C29.08 127.123 27.2935 131.078 27.0321 140.355C26.7598 150.019 28.1601 162.903 30.2775 181.96L35.6027 229.887C39.8736 268.325 42.9604 295.872 48.0281 316.744C53.0051 337.243 59.5147 349.555 69.511 358.502C79.4922 367.436 92.5066 372.554 113.598 375.241C135.058 377.974 163.006 378 201.977 378C223.984 378 242.477 377.992 258.271 377.492C265.257 387.989 274.673 396.73 285.714 402.913C263.687 405 236.522 405 202.94 405H201.014C163.216 405 133.55 405 110.187 402.024C86.2578 398.976 67.1512 392.626 51.5041 378.621C35.872 364.629 27.4517 346.432 21.7904 323.114C16.2671 300.365 13.0152 271.097 8.87465 233.83L3.3326 183.952C1.35287 166.139 -0.288869 151.368 0.0428429 139.595C0.391567 127.218 2.93479 115.85 11.3143 106.487C19.6938 97.1252 30.7123 93.3421 42.9746 91.6287C54.6388 89.999 69.5012 89.9994 87.4236 90L99.35 90ZM126.663 90H277.289C270.913 54.1889 239.62 27 201.977 27C164.334 27 133.036 54.1926 126.663 90Z" />
    </svg>
      </span><span> Arama sonucu bulunamadı.</span></div></div>
       `)
    }
  },

  rangeSliderFn() {
    function data(element, key) {
      return element.getAttribute("data-" + key)
    }

    function createSlider(slider) {
      noUiSlider.create(slider, {
        start: [
          Number(data(slider, "start-value")),
          Number(data(slider, "end-value")),
        ],
        margin: Number(data(slider, "step")),
        step: Number(data(slider, "step")) || 1,
        range: {
          min: Number(data(slider, "min-value")),
          max: Number(data(slider, "max-value")),
        },
        tooltips: true,
        connect: true,
        pips: {
          mode: "steps",
          stepped: true,
          density: 1,
        },
        format: {
          to: function (value) {
            return value + ""
          },
          from: function (value) {
            return value.replace("", "")
          },
        },
      })
      slider.noUiSlider.on("update", function (values, handle) {
        // this.closest(".product-filter").querySelector(".box").value =
        //   values[handle]
        this.target.closest(".product-filter").querySelector(".box").value =
          values
      })
    }

    Array.prototype.forEach.call(
      document.querySelectorAll(".js-slider-range"),
      createSlider
    )

    $("body").delegate(".js-product-range-reset", "click", function () {
      var item =
        this.closest(".product-filter").querySelector(".js-slider-range")
      if (item) {
        item.noUiSlider.reset()
      }
    })
  },

  mainCardSliderFn() {
    var mainCardSwiper = new Swiper(".js-main-card-slider", {
      spaceBetween: 40,
      slidesPerView: 3,
      preloadImages: false,
      centeredSlides: true,
      watchSlidesProgress: true,
      lazy: true,
      speed: 400,
      navigation: {
        prevEl: ".btn-prev-main-card",
        nextEl: ".btn-next-main-card",
      },
      breakpoints: {
        320: {
          slidesPerView: 1.1,
          centeredSlides: true,
          spaceBetween: 15,
        },
        576: {
          slidesPerView: 1.5,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        992: {
          spaceBetween: 40,
          slidesPerView: 2.6,
        },
      },
      on: {
        init: function (i) {
          setTimeout(function () {
            i.lazy.loadInSlide(i.activeIndex + 2)
            i.lazy.loadInSlide(i.activeIndex - 1)
            i.lazy.loadInSlide(i.activeIndex - 2)
          }, 0)
        },
        slideChange: function (i) {
          setTimeout(function () {
            i.lazy.loadInSlide(i.activeIndex + 2)
            i.lazy.loadInSlide(i.activeIndex - 1)
            i.lazy.loadInSlide(i.activeIndex - 2)
          }, 0)
        },
      },
    })
  },
  contactSelectFn() {
    $(".js-select-i select").each(function (e, i) {
      $(".js-select-i select").change(function () {
        if (
          $(".js-subject-select select").val() !== "" &&
          $(".js-country-select select").val() !== ""
        ) {
          $(".contact__content").addClass("active")
          $(".contact__info__text").remove()
        }
      })
    })
  },

  productDetailSliderFn() {
    var productDetailSwiper = new Swiper(".js-product-detail-slider", {
      spaceBetween: 0,
      slidesPerView: 1,
      speed: 400,
      observer: true,
      observeParents: true,
      lazy: true,
      pagination: {
        el: ".js-product-detail-slider .swiper-pagination",
        type: "bullets",
      },
    })
  },
  splitWordFn() {
    const splitWordItem = document.querySelectorAll(".js-split-words")
    var words = []
    splitWordItem.forEach((el) => {
      words = el.innerHTML.split(" ")
      el.innerHTML = ""

      words.forEach((e) => {
        const span = document.createElement("span")
        span.innerHTML = e
        el.append(span)
      })
    })
  },
  introHighlightFn() {
    if (window.matchMedia("(min-width: 992px)").matches) {
      const modal = document.querySelector(".highlight-modal")
      const finishBtn = document.querySelector(".highlight-modal-last")
      const closeBtn = document.querySelector(".highlight-modal__close")
      const dataLang = document.querySelector("html").getAttribute("lang")
      const overlay = document.createElement("div")

      setTimeout(() => {
        const getStorageItem = JSON.parse(
          localStorage.getItem(`intro-${dataLang}`)
        )
        if (getStorageItem !== true) {
          modal.classList.remove("closed")
          const multipleSearchPos = document
            .querySelector(".header__search-tab__item[data-filter='multiple']")
            .getBoundingClientRect()
          const defaultSearchPos = document
            .querySelector(".header__search-tab__item[data-filter='single']")
            .getBoundingClientRect()
          console.log(defaultSearchPos)

          const offerListPos = document
            .querySelector(".offer-basket__top-icon")
            .getBoundingClientRect()

          const highlightSwiper = new Swiper(".js-highlight-swiper", {
            slidesPerView: 1,
            spaceBetween: 0,
            modules: [EffectFade, Navigation, Pagination],
            navigation: {
              prevEl: ".highlight-modal-prev",
              nextEl: ".highlight-modal-next",
            },
            pagination: {
              el: ".highlight-modal .swiper-pagination",
              clickable: true,
            },
            effect: "fade",
            fadeEffect: {
              crossFade: true,
            },
            on: {
              init(e) {
                if (e.realIndex == 0) {
                  modal.style.top = multipleSearchPos.y + 20 + "px"
                  modal.style.left =
                    multipleSearchPos.x -
                    modal.offsetWidth / 2 +
                    multipleSearchPos.width / 2 +
                    "px"
                }
                if (e.isBeginning) {
                  modal.classList.add("highlight-modal--first")
                }
              },
              slideChange(e) {
                if (e.realIndex == 0) {
                  modal.style.top = multipleSearchPos.y + 20 + "px"
                  modal.style.left =
                    multipleSearchPos.x -
                    modal.offsetWidth / 2 +
                    multipleSearchPos.width / 2 +
                    "px"
                } else if (e.realIndex == 1) {
                  modal.style.top = defaultSearchPos.y + 30 + "px"
                  modal.style.left =
                    defaultSearchPos.x -
                    modal.offsetWidth / 2 +
                    defaultSearchPos.width / 2 +
                    "px"
                } else if (e.realIndex == 2) {
                  modal.style.left =
                    offerListPos.x -
                    modal.offsetWidth +
                    75 +
                    offerListPos.width / 2 +
                    "px"
                }

                if (e.isEnd) {
                  modal.classList.add("highlight-modal--last")
                } else {
                  modal.classList.remove("highlight-modal--last")
                }

                if (e.isBeginning) {
                  modal.classList.add("highlight-modal--first")
                } else {
                  modal.classList.remove("highlight-modal--first")
                }
              },
            },
          })
          overlay.className = "highlight-modal__overlay"
          document.querySelector("body").append(overlay)
          document.querySelector("main").style.pointerEvents = "none"
          document.querySelector("header").style.pointerEvents = "none"
        }
      }, 1500)
      if (finishBtn) {
        finishBtn.addEventListener("click", () => {
          modal.classList.add("closed")
          window.localStorage.setItem(`intro-${dataLang}`, true)
          overlay.remove()
          document.querySelector("main").style.pointerEvents = "unset"
          document.querySelector("header").style.pointerEvents = "unset"
        })
      }
      if (closeBtn) {
        closeBtn.addEventListener("click", () => {
          modal.classList.add("closed")
          window.localStorage.setItem(`intro-${dataLang}`, true)
          overlay.remove()
          document.querySelector("main").style.pointerEvents = "unset"
          document.querySelector("header").style.pointerEvents = "unset"
        })
      }
    }
  },
  countUpFn() {
    var countTupVal = $(".js-count-item")

    countTupVal.each(function (index) {
      var value = $(countTupVal[index]).html()
      var countUpV = new CountUp(countTupVal[index], value, {
        enableScrollSpy: true,
        scrollSpyDelay: 100,
        scrollSpyOnce: true,
        duration: 3,
        useEasing: true,
        separator: ".",
        suffix: countTupVal[index].getAttribute("data-suffix"),
      })
      countUpV.handleScroll()
    })
  },

  init: function () {
    app.iconSpriteFn()
    app.filterTabFn()
    app.load()
    app.headerFn()
    app.headerLanguageFn()
    app.sharePopupFn()
    app.heroSearchFn()
    app.heroSliderFn()
    app.mainTab()
    app.productContentSliderFn()
    app.accordionFn()
    app.productCardSlider()
    app.productNumber()
    app.uikitFunctions()
    app.scrollContainFn()
    app.switchFn()
    app.select2Options()
    app.inputFn()
    app.productFilterFn()
    app.rangeSliderFn()
    app.mainCardSliderFn()
    app.productDetailSliderFn()
    app.contactSelectFn()
    app.rotaFinderFn()
    app.noResultFn()
    app.sortListFn()
    app.splitWordFn()
    app.introHighlightFn()
    app.countUpFn()
  },
}

function docReadied(fn) {
  window.addEventListener("DOMContentLoaded", fn)
}
function docResized(fn) {
  window.addEventListener("resize", fn)
}
function docScrolled(fn) {
  window.addEventListener("scroll", fn)
}
docReadied(() => {
  app.init()
})

docResized(() => {
  app.resized()
})

docScrolled(() => {})

export default { docResized, docReadied, docScrolled }
